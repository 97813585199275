



















import {Component, Prop, Vue} from 'vue-property-decorator';

import ObjectiveLists from "@/components/objectives/ObjectiveLists.vue";



@Component({
  components: {ObjectiveLists}
})

export default class ObjectivesNotLinkedToNorthstar extends Vue {


  page: number = 1;
  sortByDateType: string = this.$store.state.objective_sort_type;

  @Prop({default: 'all', required: false})
  selectedBoardId!: string;

  @Prop({default: 'all', required: false})
  selectedStatus!: string;

  @Prop({default: 'all', required: false})
  selectedUser!: string

  @Prop({default: null, required: false})
  startDate!: Date;

  @Prop({default: null, required: false})
  endDate!: Date;

  @Prop({required: true})
  origin!: string;

  @Prop({required: true})
  originId!: string;

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  mounted() {
    this.$events.listen('sort_by_date_master_dashboard', eventData => {

      this.sortByDateType = eventData;
    })
  }


}

